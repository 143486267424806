import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { UserService } from "../service/user.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface LiveState {
  begin: any;
  end: any;
  userTableValue: any;
  findText: any;
  searchText: any;
  page: any;
  maxCount: any;
  details: any;
  summary: any;
}

export class BetLive extends Component<any, LiveState> {
  userService: any = new UserService();
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      summary: {},
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      details: []
    };

    this.handlePageChange = this.handlePageChange.bind(this);

    this.get_live_bet_list(0);
  }

  componentDidMount() {}

  get_live_bet_list = (page: any) => {
    this.userService
      .agent_get_live_bet_list(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        this.state.searchText
      )
      .then((data: any) => {
        for (let match of data.matchs) {
          this.userService
            .get_match_detail_by_betId(match._id)
            .then((detailData : any) => {
              this.state.details[detailData.betId] = detailData.detail;

              this.setState({
                details: this.state.details,
                maxCount: data.maxCount,
                summary: data.summary
              });
            });
        }

        this.setState({
          userTableValue: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px"
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_live_bet_list(page);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      return userTableValue.map((contact: any, i: any) => {
        return (
          <BetMatchTable
            bet={contact}
            key={contact._id}
            mode={"live"}
            details={this.state.details[contact._id]}
          ></BetMatchTable>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div>
              <div className="p-col-12 ">
                <div className="card card-w-title" style={{ padding: "0px" }}>
                  <div className="p-md-11">
                    <div className="card-title">라이브 배팅정보</div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          placeholder="Begin"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.begin}
                          showWeek={false}
                          onChange={e => this.setState({ begin: e.value })}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          placeholder="End"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          showWeek={false}
                          value={this.state.end}
                          onChange={(event: any) =>
                            this.setState({ end: event.value })
                          }
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        {/* <Dropdown
                              options={this.state.cities}
                              value={this.state.findText}
                              onChange={(event: any) =>
                                this.setState({ findText: event.value })
                              }
                              autoWidth={true}
                            /> */}
                      </div>
                      <div className="p-col-12 p-md-2">
                        <span className="md-inputfield">
                          <InputText
                            onChange={(event: any) => {
                              this.setState({
                                searchText: event.target.value
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div className="p-col-12 p-md-2">
                        <span className="md-inputfield">
                          <Button
                            label="검색"
                            onClick={() => {
                              this.handlePageChange(0);
                            }}
                          />
                        </span>
                      </div>
                      <div className="p-col-12 p-md-2">
                        <DataTable
                          style={{ padding: "0px" }}
                          value={this.state.summary}
                          paginatorPosition="both"
                          rows={20}
                        >
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="error" text="BET" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalBet)}</span>
                                </div>
                              );
                            }}
                          />
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="info" text="WIN" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalWin)}</span>
                                </div>
                              );
                            }}
                          />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
