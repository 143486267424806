import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import cookie from "react-cookies";


import { UserService } from "../service/user.service";
import { AxiosMag } from "../service/axios.service";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

interface State {
  id: string;
  pw: string;

  mode : string;
}
export default class Login extends Component<any, State> {
  userService = new UserService();
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      pw: "",
      mode: "hide"
    };
  }

  render() {
    // const [cookies, setCookie] = useCookies(["session"]);
    // this.state = { userId: cookie.load("userId") };

    const DoLogin = () => {
      console.log(this.state.id, this.state.pw);
      this.userService
        .do_login_agent(this.state.id, this.state.pw)
        .then((data : any) => {
          if (data.status == "success") {
            cookie.save("session", data.session, { path: "/" });

            //setCookie("session", data.session, { path: "/" });

            AxiosMag.SetSession(data.session);
            window.location.href = "/#/dashboard";
            // this.setState({ user: data.user });
          } else {
            window.location.reload();
          }
        });
    };

    return (
      <>
      {/* <div style={{zIndex:9999, position:'relative'}}>
      </div> */}
    
    <div style={{ position: "relative",  height : '100%' }} >
      <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // background: "red",
            opacity: 0.1
          }}

          onClick={()=> this.setState({mode : 'show'})}
        />
        <div style={{height : '100%' }} 
                    onClick={()=> this.setState({mode : 'show'})}
                    >
          <iframe src="https://www.gmarket.co.kr" width="100%" height="100%" >
          </iframe>
        </div>

        {
          this.state.mode ===  'show' && (
            <div className="login-body" >
            <div className="login-panel ui-fluid" style={{height: '250px', top: '350px'}}>
              <div className="login-panel-header">
                {/* <img
                  src="//pics.gmarket.co.kr/pc/single/kr/common/image__logo.png"
                  alt="sapphire"
                /> */}
              </div>
              <div className="login-panel-content">
                <div className="p-grid">
                  {/* <div className="p-col-12">
                    <h1>SAPPHIRE NETWORK</h1>
                    <h2>Welcome, please use the form to sign-in</h2>
                  </div> */}
                  <div className="p-col-12">
                    <span className="md-inputfield">
                      <InputText
                        style={{ width: "100%" }}
                        value={this.state.id}
                        onChange={(event: any) => {
                          this.setState({
                            id: event.target.value
                          });
                        }}
                      />
                      <label>아이디</label>
                    </span>
                  </div>
                  <div className="p-col-12">
                    <span className="md-inputfield">
                      <InputText
                        type="password"
                        style={{ width: "100%" }}
                        value={this.state.pw}
                        onChange={(event: any) => {
                          this.setState({
                            pw: event.target.value
                          });
                        }}
                      />
                      <label>비밀번호</label>
                    </span>
                  </div>
                  <div className="p-col-6">
                  </div> 
                  <div className="p-col-6" style={{ textAlign: "right" }}>
                    <Button
                      label="로그인"
                      onClick={() => {
                        DoLogin();
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
            )
        }
      
      </div>

      
      </>
   
    );

  }
}
