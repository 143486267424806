import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
} from "../../utility/help";

interface CasinoProp {
  mode: any;
  bets: any;
}

interface CasinoState {
  active: any;
}

export class BetCasinoTable extends Component<CasinoProp, CasinoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      active: 0,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {};

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };

  handlePageChange = (e: any) => {
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const bets = this.props.bets;

    return (
      // <DataTable
      //   value={bets}
      //   paginatorPosition="both"
      //   rows={20}
      //   sortOrder={-1}
      //   scrollable={true}
      // >
      //   <Column
      //     style={{ textAlign: "center", width: "120px" }}
      //     header="아이디"
      //     field="userID"
      //   />

      //   <Column
      //     style={{ textAlign: "center", width: "120px" }}
      //     field="bet"
      //     header="배팅금"
      //     body={(rowData: any, column: any) => {
      //       return <div>{numTomoeny(rowData.balanceBet)}</div>;
      //     }}
      //   />
      //   <Column
      //     style={{ textAlign: "center", width: "120px" }}
      //     field="win"
      //     header="이긴금액"
      //     body={(rowData: any, column: any) => {
      //       return <div>{numTomoeny(rowData.balanceWin)}</div>;
      //     }}
      //   />

      //   <Column
      //     style={{ textAlign: "center", width: "120px" }}
      //     header="게임업체"
      //     body={(rowData: any, column: any) => {
      //       return <div>{rowData.thirdParty}</div>;
      //     }}
      //   />

      //   <Column
      //     style={{ textAlign: "center", width: "150px" }}
      //     header="배팅일"
      //     body={(rowData: any, column: any) => {
      //       return <div>{convertDate(rowData.betTime)}</div>;
      //     }}
      //   />
      // </DataTable>
      <DataTable
      value={bets}
      paginatorPosition="both"
      rows={20}
      sortOrder={-1}
      scrollable={true}
    >
      <Column
        style={{ textAlign: "center", width: "120px" }}
        header="아이디"
        body={(rowData: any, column: any) => {
          if(rowData.id != null){
          return <div onClick={() => {}}>{rowData.id}({rowData.agent})</div>;
          }

          return <div onClick={() => {}}>{rowData.userID}</div>;
        }}
      />

      <Column
        style={{ textAlign: "center", width: "120px" }}
        field="bet"
        header="배팅금"
        body={(rowData: any, column: any) => {
          return <div>{numTomoeny(rowData.balanceBet)}</div>;
        }}
      />
      <Column
        style={{ textAlign: "center", width: "120px" }}
        field="win"
        header="이긴금액"
        body={(rowData: any, column: any) => {
          return <div>{numTomoeny(rowData.balanceWin)}</div>;
        }}
      />

        
<Column
        style={{ textAlign: "center", width: "120px" }}
        field="win"
        header="롤링"
        body={(rowData: any, column: any) => {
          if(rowData.giveBalance == null || rowData.percent == null){
            return <></>
          }
          return <div>{numTomoeny(rowData.giveBalance)} ({(rowData.percent).toFixed(3)}%)</div>;
        }}
      />
      
      <Column
        style={{ textAlign: "center", width: "120px" }}
        field="win"
        header="보유"
        body={(rowData: any, column: any) => {
      
          return <div>{numTomoeny(rowData.afterBalance)}</div>;
        }}
      />

      <Column
        style={{ textAlign: "center", width: "120px" }}
        header="게임업체"
        body={(rowData: any, column: any) => {
          return <div>{rowData.thirdParty}</div>;
        }}
      />



      <Column
        style={{ textAlign: "center", width: "150px" }}
        header="배팅일"
        body={(rowData: any, column: any) => {
          return <div>{convertDate(rowData.betTime)}</div>;
        }}
      />
    </DataTable>
    );
  };
}
