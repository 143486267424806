import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  ConvertNick
} from "../../utility/help";

interface BalanceProps {
  mode: any;
  balances: any;
  level : any;
  OnEditItem: (par1: any, par2: any, par3: any) => any;
  OnSelectItem: (par: any) => any;
}

export class BalanceTable extends Component<BalanceProps> {
  constructor(props: BalanceProps) {
    super(props);
    this.state = {
      deposits: [],
      page: 0,
      pages: {},
      field: "",
    };
    this.header = this.header.bind(this);
  }

  componentDidMount = () => {};

  header = (up: any, down: any) => {
    return (
      <div>
        <span>{up}</span>
        <br />
        <span>{down}</span>
        <br />
      </div>
    );
  };

  render = () => {
    const mode = this.props.mode;
    const balances = this.props.balances;
    const OnEditItem = this.props.OnEditItem;
    const OnSelectItem = this.props.OnSelectItem;
    const CheckRender = () => {
      if (mode === "deporsitAndwithdraw") {
        return <div style={{ width: "0px" }}></div>;
      }

      return (
        <Column
          style={{ textAlign: "center", width: "3px" }}
          header=""
          body={(rowData: any, column: any) => {
            return (
              <div>
                <Checkbox
                  value="n"
                  inputId={"idx" + rowData.idx}
                  onChange={() => {
                    OnSelectItem(rowData.idx);
                  }}
                  checked={rowData.sel === "y" ? true : false}
                />
                <label htmlFor="cb1" className="p-checkbox-label"></label>
              </div>
            );
          }}
        />
      );
    };
    return (
      <DataTable
        value={balances}
        paginatorPosition="both"
        rows={20}
        sortOrder={-1}
        scrollable={true}
      >
        <Column
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "120px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
          header="종류"
          body={(rowData: any, column: any) => {
            if (rowData.type === "deposit") {
              return <Message severity="error" text="충전" />;
            }

            if (rowData.type === "withdraw") {
              return <Message severity="info" text="환전" />;
            }
            return "미정의";
          }}
        />


        <Column
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "120px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
          header="아이디"
          field="id"
        />
        <Column
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "120px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
          header="입금자/출금자"
          field="bankowner"
        />

        <Column
          style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
          header="충전/환전"
          body={(rowData: any, column: any) => {
            let color = getDepositColor();
            if (rowData.type == "withdraw") {
              color = getWithdrawColor();
            }
            return (
              <span style={{ color: color }}>
                {numTomoeny(rowData.balance)}
              </span>
            );
          }}
          field="regdate"
        />
        <Column
          style={{ textAlign: "center", width: "180px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "180px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
          header={"신청날짜"}
          body={(rowData: any, column: any) => {
            return <span>{convertDate(rowData.regdate)}</span>;
          }}
        />
        <Column
          style={{ textAlign: "center", width: "180px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "180px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
          header={"완료날짜"}
          body={(rowData: any, column: any) => {
            return <span>{convertDate(rowData.setdate)}</span>;
          }}
        />
     
        <Column
          style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
          header={"상태"}
          body={(rowData: any, column: any) => {
            return converBalanceStatus(rowData.status);
          }}
        />
      </DataTable>
    );
  };
}
