import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import {Fieldset} from 'primereact/fieldset';

import { SelectButton } from "primereact/selectbutton";
import { confirmAlert } from "react-confirm-alert"; // Import


import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import {
  OpenAgentDetail,
  numTomoeny,
  convertDate,
  converBalanceStatus,
  ConverAgentExBalanceType,
  GetTodayToString,
  convertFullDate
} from "../utility/help";

interface AgentState {
  logs: any;
  activePage: any;
  maxCount: any;
  begin : any
  end : any
  viewType: any
  balance: any
  select : any
  agentBalance : any;

  banknum: any;
  bankowner : any;
  bankname : any;
}

export class BalanceOut extends Component<any, AgentState> {
  agentService: AgentService = new AgentService();

  constructor(props: any) {
    super(props);
    
    let today  = GetTodayToString()
    this.state = {
      logs: [],
      activePage: 1,
      maxCount: 1,
      begin : today.begin,
      end : today.end,
      viewType: 1000,
      balance : 0,
      agentBalance : 0,
      select : { name: '에이전트 아이디', code: 'agentId' },
      banknum: '',
      bankowner : '',
      bankname : '',
    };

    this.agent_get_rolling_log(0)
  }

  componentDidMount() {
  }


  agent_get_rolling_log = async(skip : number) =>{
    let balance = await this.agentService.agent_get_rolling_log(convertFullDate(this.state.begin), convertFullDate(this.state.end), skip, 'withdraw')
    if(balance.status === 'success'){
      this.setState({
        logs : balance.logs ,
        maxCount: balance.maxCount,
      });
    }
  }
  

  handleExchangeMoney = (ex: any) => {
    
    if (ex.type != "balance") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이전트 알을 충전신청합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {

              this.agentService.agent_exchange_money(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    } 
  };

  handleAgentBalance = (skip : any, selectView :string) => {

    // this.setState({ activePage: skip });
    // this.agentService.agent_get_agent_balance_list(convertFullDate(this.state.begin), convertFullDate(this.state.end), skip, selectView).then((s) => {
    //   this.setState({
    //     maxCount: s.maxCount,
    //     balances: s.balance,
    //   });
    // });

  };

 

  render = () => {
    return (
      <div>
        <div className="content">
          <div className="card card-w-title">
              <div className="card-title">보유중인 롤링금</div>
              <div className="p-fluid p-grid p-formgrid">
          

                <div className="p-field p-col-12 p-md-12">
                  <label htmlFor="basic">보유중인 롤링금</label>
                  <InputText id="username"  readOnly value={numTomoeny(this.state.agentBalance)}/>
                </div>

                {/* <div className="p-field p-col-12 p-md-12">
                  <label htmlFor="basic">환전할 금액</label>
                  <InputText id="username"   readOnly value={`은행 :  ${this.state.bankname} / 계좌번호 :  ${this.state.banknum} / 예금주 : ${this.state.bankowner}`} />
                </div> */}

                <div className="p-field p-col-12 p-md-12">
                  <label htmlFor="basic">출금할 머니</label>
                  <InputText id="username"  value={this.state.balance}  onChange={(e : any) => { this.setState({ balance :  Number(e.target.value)})}} />
                </div>
              </div>

              

              <div className="p-col-12 p-md-12">
                 <SelectButton   
                     value={this.state.viewType}
                     options={[
                       {name: '1,000', value: 1000},
                       {name: '10,000', value: 10000},
                       {name: '100,000', value: 100000},
                       {name: '500,000', value: 500000},
                       {name: '1,000,000', value: 1000000},
                       {name: '5,000,000', value: 5000000},
                       {name: '10,000,000', value: 10000000},
                       {name: '50,000,000', value: 50000000},
                       {name: '100,000,000', value: 100000000},
                   ]}  optionLabel="name" onChange={(e : any) => {
                  
                    console.log(e.target.value)
                      this.setState({ balance : this.state.balance + Number(e.target.value)})} }/>
                 </div>
  
                 <div className="p-col-12 p-md-2">
                  <span className="md-inputfield">
                    <Button
                      className="p-button-warning"
                      label="정산 신청하기"
                      onClick={() => {
                        this.handleExchangeMoney({
                          type : "rolling",
                          rolling : this.state.balance 
                        })
                      }}
                    />
                  </span>
                </div>
          </div>

          
          <div className="card card-w-title">
              <div className="p-fluid p-grid p-formgrid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="basic">시작</label>

                    <Calendar
                      placeholder="Begin"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      value={new Date(this.state.begin)}
                      onChange={(e) => this.setState({ begin: e.value })}
                      showWeek={false}

                      
                    />
                    
                    
                </div>
                <div className="p-field p-col-12 p-md-3">
                <label htmlFor="basic">종료</label>

                    <Calendar
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      value={new Date(this.state.end)}
                      onChange={(e) => this.setState({ end: e.value })}
                      showWeek={false}
                    />
                </div>

{/* 
                <div className="p-field p-col-12 p-md-2">
                  <label htmlFor="basic">타입</label>

                  <Dropdown  options={[
                        { name: '에이전트 아이디', code: 'agentId' }, ]} onChange={() => {}} optionLabel="name" value={this.state.select} />
                </div> */}
{/*                 
                <div className="p-field p-col-12 p-md-2">
                  <label htmlFor="basic">검색</label>

                  <InputText id="username"  />
                </div> */}


                <div className="p-field p-col-12 p-md-2">
                  <Button
                        className="p-button-warning"
                        label="검색"
                        onClick={() => {
                          this.agent_get_rolling_log(1)
                        }}
                      />
                </div>

              </div>
            
          </div>

          
            <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">수수료 출금 리스트</div>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    // this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.logs}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}
                >
{/* 
                <Column 
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <span style={{color : agent.type == 'change_to_balance'  ? 'red' : 'blue'}}>
                          {ConverAgentExBalanceType(agent.type)}
                        </span>
                      );
                    }}
                    field="agent"
                  /> */}

                <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="타입"
                    body={(agent: any) => {
                      return ConverAgentExBalanceType(agent.logType);
                    }}
                  />
      

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="입금 금액"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="남은금액"
                    body={(agent: any) => {
                      return numTomoeny(agent.afterbalance);
                    }}
                  />

                
{/* 
                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="예금주"
                    body={(agent: any) => {
                      return agent.bankowner;
                    }}
                  /> */}

                  {/* <Column
                     headerStyle={{ textAlign: "center",width: "220px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "220px" , borderRight: '1px solid #d8d8d8'}}
                    header="신청일"
                    body={(agent: any) => {
                      return convertDate(agent.regdate);
                    }}
                  /> */}
                  <Column
                     headerStyle={{ textAlign: "center",width: "220px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "220px" , borderRight: '1px solid #d8d8d8'}}
                    header="완료일"
                    body={(agent: any) => {
                      return convertDate(agent.regdate);
                    }}
                  />

                  {/* <Column
                     headerStyle={{ textAlign: "center",width: "220px" ,background: '#f9f9f9', color : '#000', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "220px" , borderRight: '1px solid #d8d8d8'}}
                    header="상태"
                    body={(agent: any) => {
                      return converBalanceStatus(agent.status);
                    }}
                  /> */}

                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    // this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  };
}
