import React, { Component, Children } from "react";

import { PanelMenu } from "primereact/panelmenu";
import { TieredMenu } from "primereact/tieredmenu";
import { Menu } from "primereact/menu";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Password } from "primereact/password";
import { InputMask } from "primereact/inputmask";
import { Spinner } from "primereact/spinner";
import { RadioButton } from "primereact/radiobutton";
import { Slider } from "primereact/slider";
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";

import { confirmAlert } from "react-confirm-alert";
import { TreeTable } from "primereact/treetable";
import { BalanceService } from "../service/balance.service";

import { OverlayPanel } from 'primereact/overlaypanel';
import { AlertYesNo } from "../share/alert-yes-no";
import { AgentService } from "../service/agent.service";
import { SubPaging } from "../share/sub-paging";

import {
  converAgentStatus,
  ConvertGameText,
  numTomoeny,
  OpenAgentDetail,
  GetTodayToString,
  convertAgentLogText,
  convertDate
} from "../utility/help";
import Popup from "reactjs-popup";

interface NewAgent {
  parentId: string;
  agent: string;
  nick: string;
  pass: string;
  phone: string;
  bankname: string;
  banknum: string;
  bankowner: string;

  items: NewAgent[];
}

interface State {
  userId: any
  balances: any
  begin: any
  end: any
  cities: any;
  dropdownFind: any;
  searchText: any;
  activePage : any
  maxCount : any
}


export class UserBalanceLog extends Component<any, State> {
  agentService: AgentService = new AgentService();
  balanceService = new BalanceService();


  constructor(props: any) {
    super(props);

    const today = GetTodayToString()

    this.state = {
      userId : '',
      activePage :0,
      maxCount : 0,
      balances: [],
      begin: today.begin,
      end: today.end,
      dropdownFind: "id",
      searchText: "",
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "이름", value: "bankowner" },
        { label: "에이전트", value: "agent" },
      ],
    };
    this.handleGiveAgentBalanceLog(1, '', '', new Date(today.begin), new Date(today.end));
  }

  componentDidMount() {

  }

  
// agent_give_agent_balance_log

handleGiveAgentBalanceLog = (page : any  ,find : any,userId : any ,begin : any, end : any) => {
    this.agentService.give_agent_balance_log(page, find,userId,  begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
      if (data.status == "success") {
        this.setState({balances : data.balances, maxCount : data.maxCount })
      }
    });
  };


  render() {

   
    return (
      <div>
        
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                    <div className="p-grid form-group">

                    <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="basic">시작</label>

                    <Calendar
                      placeholder="Begin"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      value={new Date(this.state.begin)}
                      onChange={(e) => this.setState({ begin: e.value })}
                      showWeek={false}

                      
                    />
                    
                    
                </div>
                <div className="p-field p-col-12 p-md-3">
                <label htmlFor="basic">종료</label>

                    <Calendar
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      value={new Date(this.state.end)}
                      onChange={(e) => this.setState({ end: e.value })}
                      showWeek={false}
                    />
                </div>

                <div className="p-col-12 p-md-2">
                          <Dropdown
                            style={{width : '100px'}}
                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                            />
                        </div>

                <div className="p-field p-col-12 p-md-2">
                  <Button
                        className="p-button-warning"
                        label="검색"
                        onClick={() => {
                          this.handleGiveAgentBalanceLog(1, this.state.dropdownFind, this.state.searchText, this.state.begin, this.state.end)
                        }}
                      />
                </div>

                </div>
              </div>
            </div>

       
            

            <div className="p-col-12">
              <div className="card">
                <div className="card-title">지급회수 내역</div>

                 <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={page => {
                    this.handleGiveAgentBalanceLog(1, this.state.dropdownFind, this.state.searchText, this.state.begin, this.state.end)
                }}
                /> 
                <DataTable
                  value={this.state.balances}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="타입"
                    body={(agent: any) => {
                      return <div>
                         {convertAgentLogText(agent.logType)} 
                      </div>;
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="소속"
                    body={(agent: any) => {
                      return (
                        <div>
                          {agent.agent}
                        </div>
                      );
                    }}
                    field="level"
                  />


                <Column
                    style={{ textAlign: "center" }}
                    header="아이디"
                    body={(agent: any) => {
                      return (
                        <div>
                          {agent.id}
                        </div>
                      );
                    }}
                    field="level"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="대상"
                    body={(agent: any) => {
                        if(agent.logType === 'withdraw_agent') return (
                            <div style={{color : 'blue'}}>{`회수 ${numTomoeny(agent.balance)}원  회수완료`}</div>
                        )
                        if(agent.logType === 'deposit_agent') return (
                            <div style={{color : 'red'}}>{`지급 ${numTomoeny(agent.balance * -1) }원  지급완료`}</div>
                        )
                        return (
                          <div style={{color : 'green'}}>{`전환 ${numTomoeny(agent.balance) }원  전환완료`}</div>
                            );
                    }}
                    field="nick"

                    // sortFunction={}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="충/환전/롤링/관리자"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                    field="balance"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="남은금액"
                    body={(agent: any) => {
                      return numTomoeny(agent.afterbalance);
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="등록일"
                    body={(agent: any) => {
                       return convertDate(agent.regdate);
                    }}
                    field="agent_tree"
                  />
                </DataTable>
                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={page => {
                    this.handleGiveAgentBalanceLog(1, this.state.dropdownFind, this.state.searchText, this.state.begin, this.state.end)
                  }}
                /> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
