import React, { Component } from "react";
import classNames from "classnames";
import { AppTopbar } from "./AppTopbar";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { AppFooter } from "./AppFooter";
import { Route } from "react-router-dom";
//import { useCookies } from "react-cookie";
import cookie from "react-cookies";
import { OverlayPanel } from 'primereact/overlaypanel';

//import { Dashboard } from "./components/Dashboard";
// import { FormsDemo } from "./components/FormsDemo";
// import { SampleDemo } from "./components/SampleDemo";
// import { DataDemo } from "./components/DataDemo";
// import { PanelsDemo } from "./components/PanelsDemo";
// import { OverlaysDemo } from "./components/OverlaysDemo";
// import { MenusDemo } from "./components/MenusDemo";
// import { MessagesDemo } from "./components/MessagesDemo";
// import { ChartsDemo } from "./components/ChartsDemo";
// import { MiscDemo } from "./components/MiscDemo";
import { EmptyPage } from "./components/EmptyPage";
import { confirmAlert } from "react-confirm-alert";

// import { UtilsDemo } from "./components/UtilsDemo";
// import { Documentation } from "./components/Documentation";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { UserList } from "./main/userlist";
import { UserConeect } from "./main/userconeect";

import { UserDetail } from "./main/userdetail";
import { Dashboard } from "./main/dashboard";



import { DeporsitAndWithdraw } from "./main/deporsitandwithdraw";
import { NoticeAgent } from "./main/noticeagent";

// import { DetailMatch } from "./main/detailmatch";
// import { DetailMini } from "./main/detailmini";

//C:\WORK\eBetting\admin\src\main\progressLive.js

//C:\WORK\eBetting\admin\src\main\betLive.js
import { BetLive } from "./main/betlive";
import { BetCasino } from "./main/betcasino";
import { BetSlot } from "./main/betslot";
import { BetMini } from "./main/betmini";
import { BetMatch } from "./main/betmatch";
import { BetFX } from "./main/betfx";

import { Balance } from "./main/balance";
import { DepositAgent } from "./main/depositagent";
import { WithdrawAgent } from "./main/withdrawagent";
import { WithdrawUser } from "./main/withdrawuser";
import { DepositUser } from "./main/deposituser";
import { BalanceOut } from "./main/balanceout";



import { BalanceGiveUser } from "./main/balancegiveuser";
import { BalanceLog } from "./main/balancelog";

import { Agent } from "./main/agent";
import { AgentBalanceList } from "./main/agentbalancelist";
import { AgentBalanceLog } from "./main/agentbalancelog";
import { AgentDetail } from "./main/agentdetail";

import { CalculateAgent } from "./main/calculateagent";
import { CalculateSubAgent } from "./main/calculatesubagent";
import { AgentNote } from "./main/agentnote";
import { AgentHelp } from "./main/agenthelp";
import { AgentNotice } from "./main/agentnotice";
import { UserBalanceLog } from "./main/userbalancelog";




import { withRouter, matchPath } from "react-router";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "./ripple.js";
import "./App.css";
import { AppConfig } from "./AppConfig";
// import { MatchDetail } from "./share/progress/match-detail";
import { AgentService } from "./service/agent.service";
import { AxiosMag } from "./service/axios.service";

import { throws } from "assert";

import SoundPlays from "./soundplay";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers
} from "./utility/help";
interface Props {}
interface State {
  horizontal: boolean;
  topbarSize: string;
  topbarColor: string;
  menuColor: string;
  layoutColor: string;
  themeColor: string;
  menuActive: boolean;
  configDialogActive: boolean;
  topbarUserMenuActive: boolean;
  admin: any;
  management: any;
  changBalance : number;
  changeBalance : number;
  helpCount: number;
  noteCount: number;
}


// name: cookies.get('session') || 'Ben'

class App extends Component<Props, State> {
  layoutContainer: any;
  agentService: any = new AgentService();

  chageBalance = React.createRef() as any;
  changeBalance = React.createRef() as any;


  constructor(props: any) {
    super(props);

    this.state = {
      admin: {},
      management: {},
      horizontal: true,
      topbarSize: "large",
      topbarColor: "layout-topbar-blue",
      menuColor: "layout-menu-light",
      layoutColor: "blue",
      themeColor: "blue",
      menuActive: false,
      configDialogActive: false,
      topbarUserMenuActive: false,
      changBalance : 0,
      changeBalance : 0,

      helpCount : 0,
      noteCount : 0,
    
    };

    // this.onWrapperClick = this.onWrapperClick.bind(this);
    // this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarUserMenuButtonClick = this.onTopbarUserMenuButtonClick.bind(
      this
    );
    // this.onTopbarUserMenuClick = this.onTopbarUserMenuClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
    this.changeTopbarSize = this.changeTopbarSize.bind(this);
    this.changeMenuToHorizontal = this.changeMenuToHorizontal.bind(this);
    this.changeMenuTheme = this.changeMenuTheme.bind(this);
    this.changeComponentTheme = this.changeComponentTheme.bind(this);
    this.changePrimaryColor = this.changePrimaryColor.bind(this);
    this.onToggleBlockBodyScroll = this.onToggleBlockBodyScroll.bind(this);
    this.isHorizontalMenuActive = this.isHorizontalMenuActive.bind(this);
  
    setInterval(() => {
      this.update();
    }, 10000);
    this.update();
  }

  
  update = () =>{
    try{

      if(location.hash.indexOf('agentDetail') >= 0){
        return 
      }
  
      let session = cookie.load("session");
      AxiosMag.SetSession(session);
  
  
      this.agentService.agent_check_health(session).then((data : any) => {
        if (data.status == "success") {
  
          this.setState({admin: data.admin, helpCount : data.helpCount, noteCount : data.noteCount});
    
          if(data.helpCount >= 1){
            SoundPlays('agentHelpCount')
          }
          
  
        } else {
          cookie.remove("admin", { path: "/" });
  
          window.location.hash = '/'
          this.setState({
            admin: null,
          });
        }
      });

    }
    catch
    {}
   
   }

  menuClick = false;

  configMenuClick = false;

  userMenuClick = false;

  menu: any[] = [];

  // onWrapperClick(event: any) {
  //   if (!this.menuClick) {
  //     this.setState({ menuActive: false });

  //     if (!this.configMenuClick) {
  //       this.unblockBodyScroll();
  //     }
  //   }

  //   if (!this.userMenuClick) {
  //     this.setState({ topbarUserMenuActive: false });
  //   }

  //   this.userMenuClick = false;
  //   this.menuClick = false;
  //   this.configMenuClick = false;
  // }

  
  // onMenuButtonClickActive(event: any, isMenuButtonActive: any) {

  //   this.menuClick = isMenuButtonActive 

  //   this.setState({ topbarUserMenuActive: false });

  //   event.preventDefault();
  // }

  // onMenuButtonClick(event: any, isMenuButtonActive: any) {
  //   this.menuClick = true;

  //   if (!this.isHorizontalMenuActive()) {
  //     this.setState({ menuActive: !isMenuButtonActive }, () => {
  //       if (this.state.menuActive) {
  //         this.blockBodyScroll();
  //       } else {
  //         this.unblockBodyScroll();
  //       }
  //     });
  //   }

  //   event.preventDefault();
  // }

  onToggleBlockBodyScroll(add: boolean) {
    if (add) this.blockBodyScroll();
    else this.unblockBodyScroll();

    this.configMenuClick = true;
  }

  blockBodyScroll() {
    let className = `blocked-scroll${
      this.state.horizontal ? "-horizontal-menu" : ""
    }`;
    if (document.body.classList) {
      document.body.classList.add(className);
    } else {
      document.body.className += ` ${className}`;
    }
  }

  unblockBodyScroll() {
    let className = `blocked-scroll${
      this.state.horizontal ? "-horizontal-menu" : ""
    }`;
    if (document.body.classList) {
      document.body.classList.remove(className);
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + `${className}`.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  onTopbarUserMenuButtonClick(event: any) {
    this.userMenuClick = true;
    this.setState({ topbarUserMenuActive: !this.state.topbarUserMenuActive });

    event.preventDefault();
  }

  // onTopbarUserMenuClick(event: any) {
  //   this.userMenuClick = true;

  //   if (
  //     event.target.nodeName === "BUTTON" ||
  //     event.target.parentNode.nodeName === "BUTTON"
  //   ) {
  //     this.setState({ topbarUserMenuActive: false });
  //   }
  //   event.preventDefault();
  // }

  onRootMenuItemClick(event: any) {
    this.menuClick = true;

    if (this.isHorizontalMenuActive()) {
      this.setState({
        menuActive: !this.state.menuActive,
      });
    }
  }

  onMenuItemClick(event: any) {
    if (!event.item.items) {
      this.setState({ menuActive: false });
    }
  }

  onSidebarClick = (event: any) => {
    this.menuClick = true;
  };

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isHorizontalMenuActive() {
    return this.state.horizontal && !this.isMobile();
  }

  changeTopbarSize(event: any) {
    this.setState({ topbarSize: event.size });

    event.originalEvent.preventDefault();
  }

  changeTopbarTheme(event: any) {
    this.setState({ topbarColor: "layout-topbar-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeMenuToHorizontal(event: any) {
    this.setState({ horizontal: event.mode });

    event.originalEvent.preventDefault();
  }

  changeMenuTheme(event: any) {
    this.setState({ menuColor: "layout-menu-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeComponentTheme(event: any) {
    this.setState({ themeColor: event.theme });

    let element = document.getElementById("theme-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "theme-" + event.theme + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  changePrimaryColor(event: any) {
    this.setState({ layoutColor: event.color });

    let element = document.getElementById("layout-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "layout-" + event.color + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  componentDidMount = () => {
    console.log("componentDidMount");

    // const [cookies, setCookie] = useCookies(["session"]);
    // session: string;
    let session = cookie.load("session");
    AxiosMag.SetSession(session);

    // this.agentService.admin_get_management().then((s) => {
    //   this.setState({ management: s.management });
    // });

    
  };

  createMenu() {
    this.menu = [
      { label: "Dashboard", icon: "dashboard", to: "/dashboard" },
      { label: "유저 관리", icon: "list", to: "userlist",},
      { label: "현재 접속자", icon: "list", to: "userconeect",},
      { label: "충/환전내역", icon: "list", to: "deporsitAndwithdraw",},

      { label: "에이전트 충/환전/알", icon: "list", to: "agentBalanceList",},
      { label: "유저 알지급/회수", icon: "list", to: "balancegiveuser",},
   
      {
        label:  "배팅/롤링 내역",
        icon: "list",
        to: "betslot",
     
      },
      // {
      //   label: "일/월별 통계",
      //   icon: "list",
      //   to: "calculateagent",
      // },

      {
        label: "일/월별 통계",
        icon: "list",
        to: "calculatesubagent",
      },
      

      {
        label: "알신청/수수료전환",
        icon: "list",
        to: "balance",

      },

      {
        label: "파트너 관리",
        icon: "list",
        to: "agent",
      },
      {
        label: "문의",
        icon: "list",
        to: "agenthelp",
      },
   
    ];



  }

  
  
  
  handleExchangeBalance = (balance, ex) => {
    if (balance < 1) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "변환할 금액을 1원 이상을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {}
          }
        ]
      });
      return;
    }

    if (balance.type != "balance") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이전트 루징/포인트/롤링을 벨런스로 변환 신청을 합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    } else {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "벨런스를 환전 신청합니다 잠시만 대기해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    }
  };

  handleChangeBalance = (balance : any, ex: any) => {
     if (balance % 1000 != 0) {
       confirmAlert({
         title: "에이전트 벨런스",
         message: "충전할 알은 1,000 단위 로 입력해주세요.",
         buttons: [
           {
             label: "확인",
             onClick: () => {}
           }
         ]
       });
       return;
     }

    confirmAlert({
      title: "에이전트 벨런스",
      message: "에이전트 알을 충전신청합니다.",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.agentService.agent_change_balance(ex).then(s => {
              if (s.status == "success") {
                window.location.reload();
              }
            });
          }
        }
      ]
    });
  };

  
  handleWirteHelp = async() => {
    let data = await this.agentService.admin_wirte_agent_help({title : '-자동 계좌문의', contents : '-문의'}, ).then(data => data)
    if (data.status == "success") {
        confirmAlert({
          title: "계좌문의",
          message: "계좌문의.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "계좌문의",
          message: "계좌문의 작성 실패.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
  };


  render() {
    this.createMenu();

    if (this.state.admin == null) {
      window.location.href = "/";
    }

    const layoutContainerClassName = classNames(
      "layout-wrapper",
      "layout-menu-light",
      "layout-topbar-blue",
      "layout-menu-static",
      this.state.topbarUserMenuActive ? 'layout-menu-mobile-active' :  "layout-menu-active",
      "p-input-filled",
      
      this.state.topbarColor,
      this.state.menuColor
    );

    
            // className="layout-wrapper  layout-menu-light layout-topbar-blue layout-menu-static layout-menu-active p-input-filled"

    // if (!this.menuClick) {
    //   this.setState({ menuActive: false });

    //   if (!this.configMenuClick) {
    //     this.unblockBodyScroll();
    //   }
    // }

    // if (!this.userMenuClick) {
    //   this.setState({ topbarUserMenuActive: false });
    // }

    // this.userMenuClick = false;
    // this.menuClick = false;
    // this.configMenuClick = false;
    const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

    let hash = window.location.hash;

    // const PostPage = (match: any) => {
    //   return <div>blablabla</div>;
    // };
    if (hash.indexOf("userDetail") >= 0) {
      if(process.env.REACT_APP_DELETE_GIVE_BALANCE === 'y' ){
        return (<></>)
      }

      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/userDetail/:agentID"
              component={(match: any) => {
                return (
                  <UserDetail
                    userID={match.match.params.agentID}
                  ></UserDetail>
                );
              }}
            />
          </div>
        </div>
      );
    }
    else if (hash.indexOf("agentDetail") >= 0) {
      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/agentDetail/:agentID"
              component={(match: any) => {
                return (
                  <AgentDetail
                    agentId={match.match.params.agentID}
                  ></AgentDetail>
                );
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        className={layoutContainerClassName}
     
      >
        <div >
        <div className="layout-topbar p-shadow-4">
          <div className="layout-topbar-left">

          <ul id="gnb_sts">
                <li>
                    <ul className="_4x">
                        <li >ID</li>
                        <li><span className="_value">{this.state.admin.agent}</span></li>
                        <li><span className="_btn_interception" style={{backgroundColor : 'orange'}}  
                          onClick={() => {
                            cookie.remove("session", { path: "/" });
                            window.location.hash = '/';
                          }}

                        >로그아웃</span></li>
                    </ul>
                </li>
                <li >
                    <ul className="_4x">
                        <li >보유금</li>
                        <li><span className="_value" id="_has_cash" >{numTomoeny(this.state.admin.balance)}</span></li>
                        <li><span className="_btn_interception"  onClick={(e : any) => { this.chageBalance.current.toggle(e)}}>밸런스 충전신청</span></li>
                        <li><span className="_btn_point_to_cash"   style={{backgroundColor:'#26463a'}} onClick={(e : any) => { this.handleWirteHelp()}} >계좌문의</span></li>


                    </ul>
                    <ul className="_4x">
                        <li>보유포인트</li>
                        <li><span className="_value" id="_has_point" >{numTomoeny(this.state.admin.rolling)}</span></li>
                        <li><span className="_btn_point_to_cash"  onClick={(e : any) => { this.changeBalance.current.toggle(e)}}>포인트 캐시전환</span></li>
                    </ul>
                </li>
            </ul>
            
            <button type="button" className="layout-menu-button p-shadow-6 p-link"  onClick={this.onTopbarUserMenuButtonClick}><i className="pi pi-chevron-right"></i></button>
          </div>
      
        </div>


        <OverlayPanel ref={this.chageBalance}  id="overlay_panel" style={{width: '500px'}} showCloseIcon={false}>
          
          <div className="md-inputfield">
            <span className="p-float-label" style={{padding : '20px'}}>
                  <div className="card-title">밸런스 충전신청</div>

                    <div className="p-md-12">
                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-1">
                          <label htmlFor="input">
                            {/* {numTomoeny(Number(agent.balance))} */}
                          </label>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <InputText
                            id="input"
                             value={this.state.changeBalance}
                             onChange={(even: any) => {
                               this.setState({ changeBalance: even.target.value });
                             }}
                          />
                        </div>
                        <div className="p-col-12 p-md-5">
                          <Button
                            label="확인"
                            className="p-button-warning"
                            style={{color : 'black'}}
                            onClick={(even: any) => {
                              this.handleChangeBalance(this.state.changeBalance, {point : this.state.changeBalance , type  : "point"});
                            }}
                          />
                        </div>

                      </div>
                    </div>

            </span>
         </div> 
        </OverlayPanel>

        
        <OverlayPanel ref={this.changeBalance}  id="overlay_panel" style={{width: '500px'}} showCloseIcon={false}>
          
          <div className="md-inputfield">
            <span className="p-float-label" style={{padding : '20px'}}>
                  <div className="card-title">포인트 캐시전환</div>

                    <div className="p-md-12">
                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-1">
                          <label htmlFor="input">
                            {/* {numTomoeny(Number(agent.balance))} */}
                          </label>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <InputText
                            id="input"
                            value={this.state.changeBalance}
                            onChange={(even: any) => {
                              this.setState({ changeBalance: even.target.value });
                            }}
                          />
                        </div>
                        <div className="p-col-12 p-md-5">
                          <Button
                            label="확인"
                            className="p-button-warning"
                            style={{color : 'black'}}
                             onClick={(even: any) => {

                               this.handleExchangeBalance(this.state.changeBalance, {type : "rolling", rolling : this.state.changeBalance});

                            }}
                          />
                        </div>

                      </div>
                    </div>

            </span>
         </div> 
        </OverlayPanel>


          <div className="layout-top">
         
          <div className="menu-wrapper">
            <div className="layout-menu-container">
           

               
              <ul role="menu" className="layout-menu">
                <li className="layout-root-menuitem" role="menuitem">
                  <a data-pr-tooltip="UI Kit" type="button" className="p-ripple tooltip p-link">
                    <i className="layout-menuitem-icon pi pi-fw pi-star"></i>
                    <span className="layout-menuitem-text">고객센터</span>
                    <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                    <span className="p-ink"></span>
                  </a>
                <div>
                  <span className="layout-menuitem-text" >고객센터</span>
                </div>
                <ul role="menu">
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/agentnotice"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">공지사항</span><span className="p-ink"></span></a></li>
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/agentnote"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span  className={this.state.noteCount > 0 ?  "layout-menuitem-text neon" : "layout-menuitem-text "}>쪽지 관리</span><span className="p-ink"></span></a></li>
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/agenthelp"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className={this.state.helpCount > 0 ?  "layout-menuitem-text neon" : "layout-menuitem-text"}>고객센터</span><span className="p-ink"></span></a></li>
                </ul>
                </li>
              </ul>


              
              <ul role="menu" className="layout-menu">
                <li className="layout-root-menuitem" role="menuitem">
                  <a data-pr-tooltip="UI Kit" type="button" className="p-ripple tooltip p-link">
                    <i className="layout-menuitem-icon pi pi-fw pi-star"></i>
                    <span className="layout-menuitem-text">에이전트관리</span>
                    <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                    <span className="p-ink"></span>
                  </a>
                <div>
                  <span className="layout-menuitem-text" >에이전트관리</span>
                </div>
                <ul role="menu">
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/agent"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">에이전트관리</span><span className="p-ink"></span></a></li>
                </ul>
                </li>
              </ul>


              <ul role="menu" className="layout-menu">
                <li className="layout-root-menuitem" role="menuitem">
                  <a data-pr-tooltip="UI Kit" type="button" className="p-ripple tooltip p-link">
                    <i className="layout-menuitem-icon pi pi-fw pi-star"></i>
                    <span className="layout-menuitem-text">회원</span>
                    <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                    <span className="p-ink"></span>
                  </a>
                <div>
                  <span className="layout-menuitem-text" >회원</span>
                </div>
                <ul role="menu">
                <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/userlist"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">유저관리</span><span className="p-ink"></span></a></li>
                <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/userconeect"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">현재 접속 유저</span><span className="p-ink"></span></a></li>
                </ul>
                </li>
              </ul>


              <ul role="menu" className="layout-menu">
                <li className="layout-root-menuitem" role="menuitem">
                  <a data-pr-tooltip="UI Kit" type="button" className="p-ripple tooltip p-link">
                    <i className="layout-menuitem-icon pi pi-fw pi-star"></i>
                    <span className="layout-menuitem-text">머니</span>
                    <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                    <span className="p-ink"></span>
                  </a>
                <div>
                  <span className="layout-menuitem-text" >머니</span>
                </div>
                <ul role="menu">
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/depositagent"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">밸런스 충전</span><span className="p-ink"></span></a></li>
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/withdrawagent"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">밸런스 환전</span><span className="p-ink"></span></a></li>
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/agentbalancelog"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">밸런스 이동내역</span><span className="p-ink"></span></a></li>
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/deposituser"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">회원 충전 내역</span><span className="p-ink"></span></a></li>
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/withdrawuser"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">회원 환전 내역</span><span className="p-ink"></span></a></li>
                  {/* <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/balanceout"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">알 이동내역</span><span className="p-ink"></span></a></li> */}
                  {/* <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/userbalancelog"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">알 지급/회수 기록</span><span className="p-ink"></span></a></li> */}
                </ul>
                </li>
              </ul>


              <ul role="menu" className="layout-menu">
                <li className="layout-root-menuitem" role="menuitem">
                  <a data-pr-tooltip="UI Kit" type="button" className="p-ripple tooltip p-link">
                    <i className="layout-menuitem-icon pi pi-fw pi-star"></i>
                    <span className="layout-menuitem-text">보고서 관리</span>
                    <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                    <span className="p-ink"></span>
                  </a>
                <div>
                  <span className="layout-menuitem-text" >보고서 관리</span>
                </div>
                <ul role="menu">
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/CalculateAgent"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">입출금 관리</span><span className="p-ink"></span></a></li>
                  <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/calculatesubagent"><i className="layout-menuitem-icon pi pi-fw pi-id-card"></i><span className="layout-menuitem-text">정산 관리</span><span className="p-ink"></span></a></li>
                </ul>
                </li>
              </ul>


              <ul role="menu" className="layout-menu">
                <li className="layout-root-menuitem" role="menuitem">
                  <a data-pr-tooltip="UI Kit" type="button" className="p-ripple tooltip p-link">
                    <i className="layout-menuitem-icon pi pi-fw pi-star"></i>
                    <span className="layout-menuitem-text">게임 관리</span>
                    <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                    <span className="p-ink"></span>
                  </a>
                <div>
                  <span className="layout-menuitem-text" >게임 관리</span>
                </div>
                <ul role="menu">
                <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/betslot">
                  <i className="pi pi-facebook"></i>
                  <span className="layout-menuitem-text">슬롯 베팅 목록</span><span className="p-ink"></span></a>
                 </li>
                 <li className="" role="menuitem"><a className="p-ripple tooltip" href="#/betcasino">
                  <i className="pi pi-facebook"></i>
                  <span className="layout-menuitem-text">카지노 베팅 목록</span><span className="p-ink"></span></a>
                 </li>
                </ul>
                </li>
              </ul>

            </div>
          </div>
          </div>
        </div>

        <div className="layout-main">
          <div className="layout-content">

            <Route path="/dashboard" component={Dashboard} />
            <Route path="/userlist" component={UserList} />
            <Route path="/userconeect" component={UserConeect} />
              
            <Route path="/deporsitAndwithdraw" component={DeporsitAndWithdraw} />

            <Route path="/betmatch" component={BetMatch} />
            <Route path="/betlive" component={BetLive} />
            <Route path="/betcasino" component={BetCasino} />
            <Route path="/betslot" component={BetSlot} />
            <Route path="/betmini" component={BetMini} />
            <Route path="/betfx" component={BetFX} />

            <Route path="/balance" component={Balance} />
            <Route path="/balanceLog" component={BalanceLog} />
            <Route path="/agent" component={Agent} />
            <Route path="/agentBalanceList" component={AgentBalanceList} />
            <Route path="/agentbalancelog" component={AgentBalanceLog} />
            <Route path="/agentnote" component={AgentNote} />
            <Route path="/agenthelp" component={AgentHelp} />
            <Route path="/agentnotice" component={AgentNotice} />

            
            <Route path="/calculatesubagent" component={CalculateSubAgent} />
            <Route path="/depositagent" component={DepositAgent} />
            <Route path="/withdrawagent" component={WithdrawAgent} />
            
            <Route path="/withdrawuser" component={WithdrawUser} />
            <Route path="/deposituser" component={DepositUser} />
            <Route path="/balanceout" component={BalanceOut} />
            <Route path="/userbalancelog" component={UserBalanceLog} />
            
              
            <Route path="/balancegiveuser" component={BalanceGiveUser} />
  
            <Route path="/calculateagent" component={CalculateAgent} />
          </div>
        </div>
        <NoticeAgent></NoticeAgent>


        <AppFooter />
        <div id="agentHelpCount" style={{display: 'none'}}>0</div>

      </div>
    );
  }
}

export default App;
