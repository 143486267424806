import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { UserService } from "../service/user.service";

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
} from "../utility/help";
import { CONNREFUSED } from "dns";



interface State {
  maxCount: number;
  userTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
  level: any;
}

export class UserList extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regdate",
      sort: 1,
      maxCount: 1,
      level: 4,
      
    };
  }

  componentDidMount() {
    this.GetUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    this.userService
      .get_user_list(page, find, search, sortType, sort, "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount,
          level: data.level,
          
        });
      });
  };

  
  GetConnectUserList = () => {
    this.userService
      .get_user_health_list(0, '', '', '', 'regdate', "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount,
        });
      });
  };



  SortTable = (sortType: any, sort: any) => {
    this.setState({ sortType: sortType, sort: sort });
    this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  };

  SearchData = (search: any, find: any) => {
    this.setState({ search: search, find: find });
    this.GetUserList(0, find, search, this.state.sortType, this.state.sort);
  };

  MoveSel = (page: any) => {
    this.setState({ page: page });
    this.GetUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  };

  render() {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="p-col-6">
                  <HelpBox 
                    SearchData={this.SearchData} 
                    GetConnectUserList={this.GetConnectUserList} 
                  
              
                  ></HelpBox>
                </div>
              </div>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>

              <UserTable
                level={this.state.level}
                userTableValue={this.state.userTableValue}
                // SortTable={this.SortTable}
                mode="used"
                handleUseStauseChange={() => {}}
              ></UserTable>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
