import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";
import { useCookies } from 'react-cookie';


// import locale from "react-json-editor-ajrm/locale/en";

import { AgentService } from "../service/agent.service";
import { CustomPagination } from "../share/custom-pagination";
import { converAgentStatus, numTomoeny, convertAgentBenefitBalanceText, GetTimeStemp } from "../utility/help";
import { constrainMarkerToRange } from "@fullcalendar/core/datelib/date-range";



function RenderPopup(pop: any) {
  // console.log(pop)
  pop = pop.pop
  // let cookie = this.props.GetCookie(`pop_${pop.idx}`);

  const [cookies, setCookie] = useCookies([`popuptime_${pop.idx}`]);
  const [value, setClose] = React.useState(false)

  if(value == true){
    return <></>
  }

  if (cookies[`popuptime_${pop.idx}`] != null || GetTimeStemp() - Number(cookies[`popuptime_${pop.idx}`]) > 1000 * 60 * 60 * 8  ) {
     return (<></>);
   }

  return (
    <div className="pop01_popup1 draggable02" id="divpopup20210131045005" style={{ position: 'absolute', top: `${pop.posY}px`, left: `${pop.posX}px`, zIndex: 1000 }} >
      <div className="pop01_popup_wrap">
        <div className="pop01_popup_btn_wrap">
          <ul>
            <li><a  onClick={() => { setCookie(`popuptime_${pop.idx}`, GetTimeStemp()) }}><span className="pop01_popup_btn">8시간동안 창을 열지 않음</span></a></li>
            <li><a 
            onClick={() => {
              setClose(true)
            }}
            ><span className="pop01_popup_btn" >닫기 X</span></a></li>
          </ul>
        </div>
        <div className="pop01_popup_box" >
          <div className="pop01_popup_text" style={{}}>
            <span className="pop01_popup_font1" style={{ borderBottom: '2px solid #fff', marginBottom: '15px', color: 'white',  }}>{pop.title}</span>
            <span className="pop01_popup_font2"style={{overflowX: 'hidden'}} >
              <div dangerouslySetInnerHTML={{ __html: pop.contents }}></div>
            </span>
          </div>
        </div>
      </div>
    </div>)
};


interface State {
  items: any[];
  agents: any[];
  sel_agent: string;
  notice: any;
  activePage: number;
  maxCount: number;
  close_popup: any;
  note_popup: any[];
}

export class NoticeAgent extends Component<any, State> {
  agentService = new AgentService();

  constructor(props: any) {
    super(props);

    this.state = {
      items: [],
      agents: [{ name: "모두", value: "" }],
      sel_agent: "",
      activePage: 1,
      maxCount: 1,
      notice: {
        del: "n",
        contents: "",
        title: ""
      },

      note_popup: [],
      close_popup: {}     
    }
    // this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
     this.handleGetNotice();
    // this.handleGetAgent();
  }

  handleGetNotice = () => {
    this.agentService.admin_get_notice_agent().then(data => {
      if (data.status == "success") {
        this.setState({
          items: data.notices
        });
      }
    });
  };

  // handleGetAgent = () => {
  //   this.agentService.admin_get_agent_list().then(data => {
  //     if (data.status == "success") {
  //       let agents = this.state.agents;
  //       for (let agent of data.agents) {
  //         agents.push({
  //           name: agent.agent + " / " + agent.nick,
  //           value: agent.agent
  //         });
  //       }
  //       this.setState({
  //         agents: agents
  //       });
  //     }
  //   });
  // };

  render() {
    let items = this.state.items;

    return <div style={{width : '300px'}}>{items.map((pop, index) => <RenderPopup pop={pop}></RenderPopup>)}</div>;

  }
}
