import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import {
  OpenAgentDetail,
  numTomoeny,
  convertDate,
  converBalanceStatus,
  ConverAgentExBalanceType,
  GetTodayToString,
  convertFullDate
} from "../utility/help";

interface AgentState {
  balances: any;
  activePage: any;
  maxCount: any;
  total : any
  begin : any
  end : any
  viewType: any
  selectViewType: any
}

export class AgentBalanceList extends Component<any, AgentState> {
  AgentService: AgentService  = new AgentService()
  constructor(props: any) {
    super(props);
    
    let today  = GetTodayToString()
    console.log(today)
    this.state = {
      total : [],
      balances: [],
      activePage: 1,
      maxCount: 1,
      begin : today.begin,
      end : today.end,
      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "충전", value: "deposit" },
        { label: "환전", value: "withdraw" },
      ],
    };

    //this.handleAgentBalance = this.handleAgentBalance.bind(this);
    this.handleAgentBalance(1, this.state.selectViewType);

  }

  componentDidMount() {
  }

  handleAgentBalance = (skip : any, selectView :string) => {
    this.setState({ activePage: skip });
    this.AgentService.agent_get_agent_balance_list(convertFullDate(this.state.begin), convertFullDate(this.state.end), skip, selectView).then((s) => {
      this.setState({
        total : s.total ,
        maxCount: s.maxCount,
        balances: s.balance,
      });
    });
  };

  handleMoveSel = (page: any) => {
    this.handleAgentBalance(page, this.state.selectViewType);
  };

  render = () => {
    return (
      <div>
        <div className="content">

                <div className="card card-w-title">

                      <div className="card-title">파트너 충전/환전</div>
                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={(this.state.begin)}
                            showWeek={false}
                            onChange={(e: any) =>
                              this.setState({ begin: e.value })
                            }
                            showTime


                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Calendar
                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            showWeek={false}
                            value={(this.state.end)}
                            onChange={(e: any) =>
                            this.setState({ end: e.value })
                            }
                            showTime
                          
                          />
                        </div>
                 
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                 this.handleMoveSel(0);
                              }}
                            />
                          </span>
                        </div>
                         <div className="p-col-12 p-md-4">
                          <DataTable
                            style={{ padding: "0px" }}
                            value={this.state.total}
                            paginatorPosition="both"
                            rows={20}
                          >
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="error" text="충전" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.deposit)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="info" text="환전" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.withdraw)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={
                                <Message severity="info" text="충전 - 환전" />
                              }
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>
                                      {numTomoeny(rowData.deposit - rowData.withdraw)}
                                    </span>
                                  </div>
                                );
                              }}
                            />

                          </DataTable>
                        </div> 
                      </div>
                      
                      <div className="p-grid form-group" style={{padding : '20px'}}>

                        <div className="p-col-12 p-md-2">
                          <SelectButton
                            value={this.state.selectViewType}
                            options={this.state.viewType}
                            onChange={(event: any) => {
                              this.setState({ selectViewType: event.value });
                              this.handleAgentBalance(0, event.value);
                            }}
                          />
                        </div>
                  
                  </div>
                  </div>

          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">에이전트 충/환전/알</div>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.balances}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}

                  // onSort={this.onSortChange}
                  // header={header}
                  // sortField={this.state.sortField}
                  // sortOrder={this.state.sortOrder}
                >

                <Column 
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <span style={{color : agent.type == 'change_to_balance'  ? 'red' : 'blue'}}>
                          {ConverAgentExBalanceType(agent.type)}
                        </span>
                      );
                    }}
                    field="agent"
                  />
                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                        >
                          {agent.agent}
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="환전금액/알"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="은행"
                    body={(agent: any) => {
                      return agent.bank;
                    }}
                    // sortFunction={}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="통장번호"
                    body={(agent: any) => {
                      return agent.nick;
                    }}
                    // sortFunction={}
                  />
                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="예금주"
                    body={(agent: any) => {
                      return agent.bankowner;
                    }}
                    // sortFunction={}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "220px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "220px" , borderRight: '1px solid #d8d8d8'}}
                    header="신청일"
                    body={(agent: any) => {
                      return convertDate(agent.regdate);
                    }}
                  />
                  <Column
                     headerStyle={{ textAlign: "center",width: "220px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "220px" , borderRight: '1px solid #d8d8d8'}}
                    header="완료일"
                    body={(agent: any) => {
                      return convertDate(agent.setdate);
                    }}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "220px" ,background: '#f9f9f9', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "220px" , borderRight: '1px solid #d8d8d8'}}
                    header="상태"
                    body={(agent: any) => {
                      return converBalanceStatus(agent.status);
                    }}
                  />

                  {/* <Column body={this.actionTemplate} style={{textAlign:'center', width: '6em'}}/> */}
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
