import React, { Component } from "react";
//import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Message } from "primereact/message";

import { UserService } from "../service/user.service";
import { BalanceTable } from "../share/blaance/balance-table";
import { AlertYesNo } from "../share/alert-yes-no";
import { CustomPagination } from "../share/custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  convertFullDate,
  GetToday,
} from "../utility/help";

interface WithdrawsState {
  balances: any;
  withdraws: any;
  activePage: any;
  field: any;
  begin: any;
  end: any;
  total: any;
  maxCount: number;
  searchText: any;
  findText: any;
  dropdownFind: any;
  cities: any;
  viewType: any;
  selectViewType: any;
  level : any
}

export class WithdrawUser extends Component<any, WithdrawsState> {
  userService: UserService;
  constructor(props: any) {
    super(props);

    let today = GetToday();
    console.log(today);
    this.state = {
      maxCount: 1,
      activePage: 1,
      balances: 0,
      withdraws: [],
      field: "",
      begin: today.begin,
      end: today.end,
      total: [],
      level : 1,
      searchText: "",
      findText: "",
      dropdownFind: "",
      selectViewType: "withdraw",
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "이름", value: "bankowner" },
        { label: "에이전트", value: "agent" },
      ],
      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "충전", value: "deposit" },
        { label: "환전", value: "withdraw" },
      ],
    };

    this.userService = new UserService();
  }

  componentDidMount() {
    this.get_deposit_and_withdraw(0, this.state.selectViewType);
  }

  handlePageChange = (page : any) => {
    this.setState({ activePage: page });
    this.get_deposit_and_withdraw(page, this.state.selectViewType);
  };
  // active={activePage}
  // take={20}
  // count={this.state.maxCount}
  // handlePageChange={this.handlePageChange}

  OnEditItem = (idx: any, name: any, value: any) => {
    if (name == "bonus" || name == "rolling") {
      return;
    }

    let withdraw = this.state.withdraws.find((s: any) => s.idx == idx);
    if (withdraw != undefined) {
      withdraw[name] = value;
      this.setState({ withdraws: this.state.withdraws });
    }
  };

  OnSelectItem = (idx: any) => {
    let element = this.state.withdraws.find((s: any) => s.idx == idx);

    element.sel = element.sel == "y" ? "n" : "y";

    this.setState({ withdraws: this.state.withdraws });
  };

  get_deposit_and_withdraw = (page: any, type: any) => {
    let begin = this.state.begin;
    let end = this.state.end;
    let searchText = this.state.searchText;
    let findText = this.state.dropdownFind;

    this.userService
      .agent_get_deposit_and_withdraw(
        page,
        convertFullDate(begin),
        convertFullDate(end),
        searchText,
        findText,
        type
      )
      .then((data: any) => {
        console.log(data.total)
        this.setState({
          maxCount: data.maxCount,
          balances: data.balances,
          total: data.total[0],
          searchText: searchText,
          findText: findText,
          level: data.level,
        });
      });
  };

  render() {

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                      <div className="card-title">회원 환전 내역</div>

                      <div className="p-fluid p-grid p-formgrid">
                        <div className="p-field  p-col-12 p-md-2">
                          <Calendar
                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd "
                            value={this.state.begin}
                            onChange={(e) => this.setState({ begin: e.value })}
                            showWeek={false}
                          />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                          <Calendar
                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd "
                            value={this.state.end}
                            onChange={(e) => this.setState({ end: e.value })}
                            showWeek={false}
                          />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                          <Dropdown
                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                            />
                            <label>검색</label>
                          </span>
                        </div>
                        <div className="p-field  p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                this.get_deposit_and_withdraw(
                                  0,
                                  this.state.selectViewType
                                );
                  
                              }}
                            />
                          </span>
                        </div>
                      </div>

                  </div>
                </div>
              </div>

              <div className="card">
              <div className="card-title">합 : {this.state.total  && numTomoeny(this.state.total.withdraw) }</div>
                <div className="p-col-12 ">
                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
                  <BalanceTable
                    mode="deporsitAndwithdraw"
                    balances={this.state.balances}
                    level={this.state.level}
                    OnEditItem={this.OnEditItem}
                    OnSelectItem={this.OnSelectItem}
                  ></BalanceTable>

                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
                </div>
              </div>
            </div>
          </div>
    );
  }
}
